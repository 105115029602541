import React, {lazy, Suspense} from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

const OnePager = lazy(() => import('./pages/OnePager'));
const OrderKing = lazy(() => import('./pages/OrderKing'));
const About = lazy(() => import('./pages/About'));
const Imprint = lazy(() => import('./pages/Imprint'));
const DataPrivacy = lazy(() => import('./pages/DataPrivacy'));
const NotFound = lazy(() => import('./pages/NotFound'));

const App = props => {
   return (
       <Router>
         <Suspense fallback={<div>Loading...</div>}>
           <Switch>
             <Route exact path='/' component={OnePager} />
             <Route exact path='/about' component={About}/>
             <Route exact path='/orderking' component={OrderKing}/>
             <Route exact path='/imprint' component={Imprint}/>
             <Route exact path='/data-privacy' component={DataPrivacy}/>
             {/* <Route exact path=\"/projects/primafood\" component={Primafood}/> */}
             {/* <Route exact path=\"/projects\" component={ProjectsPage}/> */}
             {/* <Route exact path=\"/volksbank\" component={Volksbank}/> */}
             <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
      )}
      
      export default App;